import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { Link } from 'gatsby';
import { Layout } from '../components/Layout/Layout';

import { SEO } from '../components/SEO/SEO';

import '../styles/about.scss';

const About: FC<PageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        pagetitle="ABOUT ME"
        pagedesc="ITエンジニアのブログです。"
        pagepath={location.pathname}
      />
      <div className="about">
        <article className="about__article">
          <h1 className="about__title">自己紹介</h1>
          <div className="about__body">
            <p className="about__p">ご覧下さりありがとうございます。</p>
            <p className="about__p">
              2018年に独立して、都内で「IT」を得意とする中小企業診断士をしています。
            </p>
            <p className="about__p">主に２つのお仕事が中心です。</p>
            <h2 className="about__h2">1. IT顧問</h2>
            <p className="about__p">
              ITの世界は専門用語が頻発、おまけにトレンドがコロコロ変わります。
              対応するのが大変ですよね。
            </p>
            <p className="about__p">
              ITを苦手とする経営者の方やアウトソースしたい方向けに、
              <a
                className="about__a"
                href="https://slack.com/intl/ja-jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                slack
              </a>
              と
              <a
                className="about__a"
                href="https://zoom.us/jp-jp/meetings.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                zoom
              </a>
              というコミュニケーションツールを使ってアドバイスをしています。
            </p>
            <p className="about__p">
              具体的には、ITシステム見積もり精査、IT活用案などです。
              もちろん、ITに関してなんでもご相談可能です。
            </p>
            <h2 className="about__h2">2. 業務システム開発</h2>
            <p className="about__p">
              プログラミングの世界では「
              <a
                className="about__a"
                href="https://ja.wikipedia.org/wiki/YAGNI"
                target="_blank"
                rel="noopener noreferrer"
              >
                YAGNIの法則
              </a>
              」というのがあります。
            </p>
            <p className="about__p">
              どういうことかと言うと、「機能は実際に必要になるまで実装しない」ということです。
              <br />
              将来的に必要になるだろうという予測で実装すると9割は使われていないと言われています。
              <br />
              それどころか複雑にしてしまうためバグのもとやメンテナンス性を損なってしまいます。
              <br />
            </p>
            <p className="about__p">
              業務システムではバランスが難しいのですが、YAGNIをこころがけてシステム開発をしています。
            </p>
            <p className="about__p">
              詳しい活動実績は
              <Link className="about__link-work" to={`/work/`}>
                コチラ
              </Link>
            </p>
          </div>
          <h1>スキルセット</h1>
          <div>
            <p>Ruby on Rails, Linux, MySQL, Docker, AWS</p>
          </div>
        </article>
      </div>
    </Layout>
  );
};

export default About;
